/* responsive */
$tablet: 738px;
$desktop:1024px;
$mediumdesktop:1100px;
$superdesktop:1200px;
$mobile:500px;


/* Github sizes */
$GitTablet: 738px;
$GitDesktop:1012px;
$GitSuperdesktop:1280px;
$GitMobileProject:544px;

/* font sizes */
$descriptionFontSizeMobile:16px;
$titleFontSizeMobile:22px;

$descriptionFontSizeTablet:16px;
$titleFontSizeTablet:34px;

$descriptionFontSizeDesktop:18px;
$titleFontSizeDesktop:40px;