@import "../variables.scss";

.menu-container {
    min-height: 100vh;
    background-color: #ffb347;
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
        width: 100%;
        margin: 1.5em 2em 2em 2em;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            margin-left: 1em;
            width: 40%;
            height: 40%;
        }
        }

        span {
            font-size: 28px;
            margin-right: 1.5em;
            color: white;
            font-weight: bold;
            font-family: 'Mukta', sans-serif;
        }

    &-list {
        margin-top: 10em;
        display:flex;
        flex-direction: column;
	    width:100%;
        padding:0;
    
        li {
            list-style: none;
            font-size: 32px;
            font-family: 'Mukta', sans-serif;
            color: #FFFF;
            text-align: center;
        }
        li:hover{
            background-color: #f2c487;
        }

    }
}
.fa-home-lg{
    color:white;
}


