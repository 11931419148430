@import "../variables.scss";

.contactUs-container {
    margin: 0em 2em 0em 2em;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
    @include forTablet {
        margin-top: 10em;
        height: 85vh;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width:95vw;
    }
    @include forDesktop{
        height: 100vh;
        margin: 4em 8em 0em 8em;
        padding-bottom: 2em;
    }

    .responsive-form {
        @include forTablet {
            display: flex;
            align-items: center;
            width: 100vw;
            justify-content: space-around;
        }
        @include forDesktop{
            margin-top: 3em;
        }
        
    }
}
.extraTitleContactUs{
    display:none;
    @include forTablet {
        display:block;
        font-size:$titleFontSizeDesktop;
        color: #585858;
    }
}
.formIcon{
    display:none;
    @include forTablet {
        display:block;
        font-size:170px;
        color:#ffb347;
        opacity:0.3;
    }
    @include forDesktop{
        font-size:322px;
    }
}

.contactUs-title {
    margin-bottom: 1em;

    h2 {
        color: #585858;
        font-weight: 600;
        font-size:$titleFontSizeMobile;
    }
}

.contactUs-form {
    padding: 1em 0.5em 1em 0.5em;
    background: white;
    box-shadow: 1px 2px 15px 3px rgba(117,117,117,0.47);
    margin-top: 4em;
    border-radius: 3px;
    @include forTablet {
        width:45vw;
    }
    @include forDesktop {
        margin-left: 8.5em;
        padding: 2em 1.5em 2em 1.5em;
    }

    .formElement {
        box-sizing: border-box;
        width: 100%;
    }

    .input {
        height: 35px;
        margin-bottom: 1.5em;
    }

    .textArea {
        height: 80px;
        margin-bottom: 0.5em;
    }

    .submitBtn {
        height: 35px;
        margin-bottom: 1.5em;
        background-color: #ffb347;
        color: white;
        border: none;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        @include forDesktop{
            font-size:16px;
        }
    }
}

.contactDetails {
    color:#585858;
    margin-top:1em;
    padding-bottom: 2em;
    @include forTablet {
        position: relative;
        left: 25px;
    }
    h4 {
        margin-top: 1em;
        margin-bottom: 0;
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-color: #ffb347;
        @include forDesktop {
            font-size: 25px;
        }
    }

    .details {
        margin: 0;
        font-size:12px;
        @include forDesktop {
         font-size: 16px;
        }
    }
    
}

.contactUs-component {
    display:block; 
    @include forTablet {
        margin-top:-8em;
    }
    @include forDesktop{
        margin-top:0em;
    }
}



