@import './variables.scss';

@mixin forTablet {
    @media all and (min-width:$GitTablet){
      @content
    }
  };

@mixin forDesktop {
    @media all and (min-width:$GitDesktop){
      @content
    }
  };

  @mixin forSuperdesktop {
    @media all and (min-width:$GitSuperdesktop){
      @content
    }
  };
  
  @mixin formediumDesktop {
    @media all and (min-width: $mediumdesktop){
      @content
    }
  };

  @mixin forMobile {
    @media all and (min-width:$mobile){
      @content
    }
  };
  
  @mixin forGitMobile {
    @media all and (min-width:$GitMobileProject){
      @content
    }
  }