@import "../scss/fontawesome.scss";
@import "../scss/solid.scss";
@import "../scss/brands.scss";
@import "../scss/regular.scss";

@import "./variables.scss";
@import "./mixins.scss";


/* Import Layouts */
@import "./layouts/AboutUs.scss";
@import "./layouts/Collaborations.scss";
@import "./layouts/Home.scss";
@import "./layouts/Menu.scss";
@import "./layouts/Navbar.scss";
@import "./layouts/Services.scss";
@import "./layouts/ContactUs.scss";


/* App.js */
h1, h2, h3{
  margin:0;
  padding:0;
}
.App {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #ffefd5;
  font-family: 'Mukta', sans-serif;
  overflow-x: hidden; /* last change */
  // before 95vh

  *::-webkit-input-placeholder {
    font-family: 'Mukta', sans-serif;
  }
  
  *:-moz-placeholder {
      /* FF 4-18 */
      font-family: 'Mukta', sans-serif;
  }
  *::-moz-placeholder {
      /* FF 19+ */
      font-family: 'Mukta', sans-serif;
  }
  *:-ms-input-placeholder {
      /* IE 10+ */
      font-family: 'Mukta', sans-serif;
  }
  *::-ms-input-placeholder {
      /* Microsoft Edge */
      font-family: 'Mukta', sans-serif;
  }
  *::placeholder {
      /* modern browser */
      font-family: 'Mukta', sans-serif;
  }
}








