@import "../variables.scss";
.services-container {
    margin: 0em 2em 0em 2em;
    @include forTablet{
        height:100vh;
    }
    @include forDesktop {
        margin: 4em 2em 0em 2em;
        width: 95vw;
        height: 100%;
      }
}
.services-titles{
    .serviceTitle{
        margin-top:1em;
        font-weight: bold;
        font-size: $titleFontSizeMobile;
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-color: #ffb347;
        margin-bottom:0.5em;
        color: #585858;
        @include forTablet {
            margin-top:0em;
            margin-bottom:0em;
            font-size:$titleFontSizeTablet;
        }
        @include forDesktop {
            margin-top:0em;
            font-size: $titleFontSizeDesktop;
          }
    }
    .serviceDescription{
        font-size: $descriptionFontSizeMobile;
        color:grey;
        text-align: left;
        margin:0;
        line-height: 1.5;
        @include forTablet{
            font-size:18px;
            margin-top:0.5em;
        }
        @include forDesktop {
            display: none;
            margin-top:0;
        }
    }
    .serviceText{
        text-align: center;
    }
    };
.services-content {
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2em;
    @include forTablet {
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
    }
    @include forDesktop {
        grid-template-columns: 1fr 1fr 1fr ;
        grid-gap: 15px;
      }
    }
.bold{
    font-weight: bold;
}

// services card component
.services_card{
    padding: 1em 0.5em 1em 0.5em;
    background: #ffb347;
    box-shadow: 1px 1px 8px 1px rgba(117,117,117,0.47);
    border-radius: 3px;
    margin-bottom:40px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:130px;
    @include forTablet {
        height:180px;
    }
    @include forDesktop {
        height:250px;
        //width: 300px;
        align-items: flex-start;
        position:relative;
        overflow: hidden;
        padding:20px 20px 20px 20px;
      }
    .services_card__text{
        font-size: 18px;
        color:white;
        text-align: center;
        margin-bottom: 0.2em;
        @include forDesktop {
            font-size:18px;
            margin-bottom: 0em;
            position:absolute;
            top:85px;
          }
    }
        .services_card__icon{
            font-size:28px;
            text-align: center;
            color:orange;
            background-color:white;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display:flex;
        justify-content: center;
        align-items: center;
        @include forTablet{
            width: 70px;
            height: 70px;
            font-size:32px;
            margin-bottom:0.5em;
        }
        @include forDesktop {
            position:absolute;
            top:25px;
            width: 50px;
            height: 50px;
            font-size:28px;
          }
        }
        .services_card__description{
            display:none;
            @include forDesktop {
                display:block;
                font-size:14px;
                text-align: justify;
                position:absolute;
                top:105px;
                color: #585858;
                width: 90%;
              }
        }
    }
