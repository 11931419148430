@import "../variables.scss";

.aboutUs-container {
    margin: 0em 2em 0em 2em;
    @include forTablet {
        height: 100vh;
        display:flex;
        justify-content: center;
    }
    @include forDesktop{
        height: 100vh;
    }
}
.aboutUs-content {
    display:flex;
    flex-direction: column;
    justify-content: center;
    @include forTablet {
        align-items: center;
    }
    @include forDesktop {
        flex-direction: row;
        margin-top:4em;
      }
    .aboutUs-content-image{
        @include forDesktop {
            width:40%;
        }
        .image{
            height:60%;
            width:100%;
            border-left:6px solid #ffb347;
            margin-top:1em;
            @include forTablet {
                height:100%;
                width:95%;
            }
            @include forDesktop {
                // For AboutUSPic.jpg uncomment below
                // width: 75%
                width: 43vw;
                height: 55vh;
            }
        }
    }
    .aboutUs-content-text{
        text-align: justify;  
        font-size: $descriptionFontSizeMobile;
        @include forTablet {
            line-height:1.6;
            font-size:$descriptionFontSizeTablet;
            margin-top: 40px;
        }
        @include forDesktop {
            line-height:1.6;
            font-size: $descriptionFontSizeDesktop;
            text-align: justify;
            width: 50%;
            // Comment line below for AboutUSPic.jpg
            margin-left: 120px;
        }
        .desktopTitle{
            display:none;
            @include forTablet{
                display:block;
                color: #585858;
                font-weight: bold;
                border-bottom:4px solid #ffb347;
                font-size:$titleFontSizeTablet;
            }
            @include forDesktop {
                font-size: $titleFontSizeDesktop;
            }
        }
    }
}
.bold{
    font-weight: bold;
}

.aboutUs-title {
    h2 {
        display: inline-block;
        color: #585858;
        font-weight: bold;
        border-bottom:4px solid #ffb347;
        margin-bottom: 1em;
        @include forTablet {
            display:none; 
        }
    }
}