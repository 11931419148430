@import "../variables.scss";

.collaborations-container {
    margin: 0em 2em 0em 2em;
}
.navbar-icons-menu{
    .menuIcon{
        margin-top:1em;
        @include forTablet{
        }
    }
    .collaborationTitle{
        margin-top:1em;
        font-weight: bold;
        font-size: $titleFontSizeMobile;
    }
    .collaborationDescription{
        font-size: $descriptionFontSizeMobile;
        color:#550A21;
        text-align: justify;
        margin:0;
    }
    };
.collaborations-content {
    display:flex;
    flex-direction: column;
    margin-top:1em;
    @include forTablet {
    }
    .collaboration{
        display:flex;
        flex-direction: column;
        width:100%;
        @include forTablet {
        }
        .collaborationText{
            font-size: 20px;
            font-weight: 700;
        }
        .collaborationComment{
            border-bottom:2px solid #ffb347;
            font-weight: 600;
            font-size: 18px;
            color:#585858;
            margin-top:0;
            padding-bottom:10px;
        }
    }
    }
.bold{
    font-weight: bold;
}