@import "../variables.scss";
  
.home-container {
    min-height: 100%;
    @include forTablet {
      display:flex;
      align-items: center;
      flex-direction: column;
      width:100vw;
    }
    .scrollButton{ /* for future scroll button styling <button className="scrollButton"><a href="#aboutUs" /><i className="fas fa-arrow-down" /></button> */
      width:70px;
      height:70px;
      border-radius:50%;
      background-color: orange;
      border:none;
    }
    .main-logo-container {
        width: '20%';
        height: 65vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 14em;
        @include forTablet {
          margin-top: 0;
         /*  background-image: url('/images/MainImage.jpg');
          background-repeat: repeat;
          background-position: 0 0; */
          background: url('../../images/MainImage.jpg') repeat 0 0;
          background-size: cover;
          height: 100vh;
          width: 100%;
          background-attachment: fixed;
          background-position-x: 63em;
          position: relative
        }
        @include forDesktop {
          background-position-x: 0em;
          background-position-y: -5em;
        }
        &-image {
            height: 8em;
            width:90%;
            margin-bottom: 0.8em;
            animation: moveInLeft 1s ease-out;
            @include forTablet {
              width: 45%;
              height: 15%;
              left: 1.5em;
              top: 6em;
              position: absolute;
            }
            @include forDesktop {
              width: 25%;
              height: 20%;
            }
        }
        &-subtitle {
          display: block;
          font-family: 'Mukta', sans-serif;
          color: #ffb347;
          font-size: $titleFontSizeMobile;
          text-align: center;
          margin: 0;
          animation: moveInRight 1s ease-out;
          @include forTablet{
            display:none;
          }
        }
      }
    &-image {
      display: none;
      @include forTablet {
        display: block;
        width: 80%;
        border-radius: 7px;
        height:60%;
        border-left:6px solid black;
    }
      @include forDesktop {
        display: none;
      }
  }
  .desktopSubtitle{
    display:none;
    @include forTablet {
      display: block;
      font-family: 'Mukta', sans-serif;
          color: #a7532d;
          font-size: 20px;
          margin-left:40%;
  }
  @include forDesktop {
    margin-left:25%;
  }
  }

  .icon-scroll,
.icon-scroll:before{
  position: absolute;
  z-index:2;
  left: 50%;
  bottom:0;
  @include forMobile{
    display:none;
  }
}
.icon-scroll{
  width: 35px;
  height: 60px;
  margin-left: -20px;
  bottom:40px;
  margin-top: -35px;
  box-shadow: inset 0 0 0 2px #ffb347;
  border-radius: 25px;
}

.icon-scroll:before{
  content: '';
  width: 9px;
  height: 9px;
  background: #ffb347;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
  }
  @keyframes moveInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    
    80% {
      transform: translateX(10px);
    }
    
    100% {
      opacity: 1;
      transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    
    80% {
      transform: translateX(-10px);
    }
    
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
  @keyframes scroll{
    0% {opacity: 1}
    100% { opacity: 0; transform: translateY(46px)}
  }
  
    
