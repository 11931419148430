@import "../variables.scss";

.navbar-container {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin: 0em 2em 2em 2em;
    display: flex;
    @include forTablet {
    display:none;
    }
    @include forDesktop {
    display:none;
    }
  }

  .navbar-icons {
   margin-top:1.5em;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    @include forTablet{
     width:20%;
     margin-top:3.5em;
      }
      &-menu {
          display: block;
          i {
              color: #ffb347;
              font-size: 26px
          }
          @include forTablet {
            display: none;
          }
        }
       
      a {
          margin: 0 0.5em;
          @include forTablet {
            width:100px;
            height:100px;
            font-size: 16px;
          }
          @include forDesktop {
            font-size: 14px;
          }

          i {
              color: #ffb347;
              font-size: 26px;
          }
      }
  }
  
  .web-sections {
    margin-right: 3em;
    display: none;
    @include forTablet {
      display: block;
      margin-top:3.5em;
    }
  }
  
  .web-sections-list {
    display: flex;
    flex-direction: row;
  }
  
  .web-sections-list > li {
    cursor: pointer;
    font-family: 'Mukta', sans-serif;
    font-size: 20px;
    list-style: none;
    margin-left: 1em;
    @include forTablet {
      font-size: 18px;
    }
    @include forDesktop {
      font-size: 20px;
    }
  }
  
  .web-sections-list > li:hover {
    border-bottom: 4px solid #ffb347;
    color: #ffb347;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
    list-style: none;
    margin-left: 1em;
  }
  
  
  
  
 